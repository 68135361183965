import { render, staticRenderFns } from "./Problem.vue?vue&type=template&id=248e719f&scoped=true"
import script from "./Problem.vue?vue&type=script&lang=js"
export * from "./Problem.vue?vue&type=script&lang=js"
import style0 from "./Problem.vue?vue&type=style&index=0&id=248e719f&prod&lang=less&scoped=true"
import style1 from "./Problem.vue?vue&type=style&index=1&id=248e719f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248e719f",
  null
  
)

export default component.exports