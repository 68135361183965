<script src="../../../../../playwright.config.js"></script>
<template>
  <div class="problem">
    <div class="float-wrapper">
      <el-button
        @click="move('title')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >제목</el-button
      >
      <el-button
        @click="move('description')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >설명</el-button
      >
      <el-button
        @click="move('sample')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >샘플</el-button
      >
      <el-button
        @click="move('ds-pro')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >DS Pro 역량 테스트
      </el-button>
      <el-button
        @click="move('hint')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >힌트</el-button
      >
      <el-button
        @click="move('test-case')"
        class="float-button"
        type="primary"
        plain
        size="small"
        >테스트케이스 업로드</el-button
      >
      <el-button
        @click="move('tutor-code')"
        class="float-button"
        type="primary"
        plain
        si="small"
        >강사님 코드</el-button
      >
      <el-button
        @click.native="submit()"
        class="float-button"
        type="primary"
        plain
        si="small"
        >저장하기</el-button
      >
    </div>
    <AdminPanel :title="title">
      <el-form
        ref="form"
        :model="problem"
        :rules="rules"
        label-position="top"
        label-width="70px"
      >
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <el-form-item
              prop="_id"
              for="id"
              label="Display ID"
              :required="
                this.routeName === 'create-contest-problem' ||
                this.routeName === 'edit-contest-problem'
              "
            >
              <el-input
                id="id"
                :placeholder="'번호'"
                v-model="problem._id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="18" :lg="18">
            <span ref="title"></span>
            <el-form-item prop="title" label="제목" required for="title">
              <el-input
                id="title"
                :placeholder="'제목'"
                v-model="problem.title"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="시간 제한(ms)" required for="timeLimit">
              <!-- 기본값 1000 -->
              <!-- <el-input
                id="timeLimit"
                type="Number"
                placeholder="시간 제한(ms)"
                v-model="problem.time_limit"
              >
            </el-input> -->

              <el-input
                style="margin-top: 7px; max-width: 250px"
                v-for="limit in language_time_limit"
                :key="limit.type"
                type="Number"
                placeholder="시간 제한(ms)"
                v-model="limit.time_limit"
              >
                <template slot="prepend">
                  <div style="width: 53px">
                    {{ limit.type }}
                  </div></template
                >
              </el-input>
              <!-- <el-input
            style="margin-top:5px;"
                id="timeLimit"
                type="Number"
                placeholder="시간 제한(ms)"
                v-model="problem.time_limit"
              >
              <template slot="prepend">Java</template>
            </el-input>
            <el-input
            style="margin-top:5px;"
                id="timeLimit"
                type="Number"
                placeholder="시간 제한(ms)"
                v-model="problem.time_limit"
              >
              <template slot="prepend">Java</template>
            </el-input> -->
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="메모리 제한(MB)" required for="memoryLimit">
              <el-input
                id="memoryLimit"
                type="Number"
                :placeholder="'메모리 제한'"
                v-model="problem.memory_limit"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item :label="'난이도'" for="difficulty">
              <el-select
                id="difficulty"
                size="small"
                :placeholder="'난이도'"
                v-model="problem.difficulty"
              >
                <el-option label="-" value="-"></el-option>
                <el-option :label="$t('m.Low')" value="Low"></el-option>
                <el-option :label="$t('m.Mid')" value="Mid"></el-option>
                <el-option :label="$t('m.High')" value="High"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item :label="'보이기 설정'" for="visible">
              <el-switch id="visible" v-model="problem.visible"> </el-switch>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="4">
            <el-form-item :label="'공유 제출'" for="share">
              <el-switch
                id="share"
                v-model="problem.share_submission"
                active-text=""
                inactive-text=""
              >
              </el-switch>
            </el-form-item>
          </el-col> -->
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item :label="'태그'" :error="error.tags" for="tag">
              <!-- 태그가 존재하면 list로 가져오기 -->
              <el-button @click.native="handleTag">태그 추가하기</el-button>

              <div>
                <el-tag
                  style="margin: 3px"
                  v-for="tag in $store.state.problem.tagList"
                  :key="tag.id"
                  closable
                  @close="handleClose(tag)"
                  :type="tag.type"
                >
                  {{ tag.name }}
                </el-tag>
              </div>

              <el-dialog
                :class="$store.state.isMobile ? 'mobile-dialog' : ''"
                :width="$store.state.isMobile ? '100vw' : '70%'"
                top="1vh"
                title="태그"
                :visible.sync="tagModal"
              >
                <AddTag @saveTag="tagModal = false"></AddTag>
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <span ref="description"></span>
            <el-form-item prop="description" id="description" label="설명">
              <VueCkEditor
                :data="problem.description"
                @setContent="setDescription"
              ></VueCkEditor>
              <!-- <ckeditor :editor="editor" v-model="problem.description" :config="editorConfig"></ckeditor> -->



            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="input_description" data-test-id="input_description" :label="'입력 설명'">
              <VueCkEditor
                :data="problem.input_description"
                @setContent="setInputDescription"
              ></VueCkEditor>
              <!-- <ckeditor :editor="editor" v-model="problem.input_description" :config="editorConfig"></ckeditor> -->



            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="output_description" data-test-id="output_description" label="출력 설명">
              <VueCkEditor
                :data="problem.output_description"
                @setContent="setOutputDescription"
              ></VueCkEditor>
            </el-form-item>
          </el-col>
        </el-row>

        <div id="sample" ref="sample">
          <el-form-item
            v-for="(sample, index) in problem.samples"
            :key="'sample' + index"
          >
            <Accordion :title="'샘플' + (index + 1)">
              <el-button
                type="warning"
                size="small"
                icon="el-icon-delete"
                slot="header"
                @click="deleteSample(index)"
              >
                삭제
              </el-button>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item :label="'입력 예시'">
                    <el-input
                      :rows="5"
                      type="textarea"
                      :placeholder="'입력 예시'"
                      v-model="sample.input"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="'출력 예시'">
                    <el-input
                      :rows="5"
                      type="textarea"
                      :placeholder="'출력 예시'"
                      v-model="sample.output"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </Accordion>
          </el-form-item>
        </div>
        <div class="add-sample-btn">
          <button type="button" class="add-samples" @click="addSample()">
            <i class="el-icon-plus"></i>샘플 추가
          </button>
        </div>

        <span ref="ds-pro"></span>
        <el-form-item
          style="margin-top: 20px"
          label="삼성전자DS Pro 역량테스트 채점방식"
          id="ds-pro"
        >
          <el-switch id="visible2" v-model="problem.main_code_visible">
          </el-switch>
        </el-form-item>

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          :label="'API 설명 - C++'"
        >
          <VueCkEditor
            :data="problem.main_code_description"
            @setContent="setMainCodeDescription"
          ></VueCkEditor>
        </el-form-item>

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="유저 코드(함수 예시) - C++"
        >
          <!-- :languages="problem.languages
          :value.sync="problem.user_code"
            
            :language="language"
            :theme="theme"
            @resetCode="onResetToTemplate"
            @changeTheme="onChangeTheme"
            @changeLang="onChangeLang"
          " -->
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.user_code"
            :language="language"
          ></CodeMirror>

        </el-form-item>
        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="메인 코드 - C++"
        >
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.main_code"
            :language="language"
          ></CodeMirror>

        </el-form-item>

        <hr v-if="problem.main_code_visible" />

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          :label="'API 설명 -Java'"
        >
          <VueCkEditor
            :data="problem.java_main_code_description"
            @setContent="setJavaMainCodeDescription"
          ></VueCkEditor>
        </el-form-item>

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="유저 코드(함수 예시) - Java"
        >
          <!-- :languages="problem.languages
          :value.sync="problem.user_code"
            
            :language="language"
            :theme="theme"
            @resetCode="onResetToTemplate"
            @changeTheme="onChangeTheme"
            @changeLang="onChangeLang"
          " -->
<!--          -->
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.java_user_code"
            :language="language"
          ></CodeMirror>

        </el-form-item>
        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="메인 코드 - Java"
        >
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.java_main_code"
            :language="language"
          ></CodeMirror>

        </el-form-item>

        <hr v-if="problem.main_code_visible" />

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          :label="'API 설명 -Python'"
        >
          <VueCkEditor
            :data="problem.python_main_code_description"
            @setContent="setPythonMainCodeDescription"
          ></VueCkEditor>
        </el-form-item>

        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="유저 코드(함수 예시) - Python"
        >
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.python_user_code"
            :language="language"
          ></CodeMirror>
        </el-form-item>
        <el-form-item
          v-if="problem.main_code_visible"
          style="margin-top: 20px"
          label="메인 코드 - Python"
        >
          <CodeMirror
            class="code-mirror-problem-wrapper"
            v-model="problem.python_main_code"
            :language="language"
          ></CodeMirror>
        </el-form-item>

        <el-form-item label="Expert 평가" :error="error.spj">
          <el-col :span="24">
            <el-checkbox
              v-model="problem.spj"
              @click.native.prevent="switchSpj()"
              >Expert 평가 사용</el-checkbox
            >
          </el-col>
        </el-form-item>
        <el-form-item v-if="problem.spj">
          <Accordion :title="'Expert 평가 코드'">
            <template slot="header">
<!--              <span>SPJ 언어</span>-->


<!--              <el-radio-group v-model="problem.spj_language">-->
<!--                <el-tooltip-->
<!--                  class="spj-radio"-->

<!--                  key="C++"-->
<!--                  effect="dark"-->
<!--                  content="C++"-->
<!--                  placement="top-start"-->
<!--                >-->
<!--                  <el-radio label="C++">C++</el-radio>-->
<!--                </el-tooltip>-->
<!--              </el-radio-group>-->
              <el-button
                type="primary"
                size="small"

                @click="compileSPJ"
                :loading="loadingCompile"
              >
                컴파일
              </el-button>
            </template>
            <code-mirror
              v-model="problem.spj_code"
              :mode="spjMode"
            ></code-mirror>
          </Accordion>
        </el-form-item>
        <span ref="hint"></span>
        <el-form-item id="hint" style="margin-top: 20px" :label="'힌트'">
          <VueCkEditor :data="problem.hint" @setContent="setHint"></VueCkEditor>
          <!-- <ckeditor :editor="editor" v-model="problem.hint" :config="editorConfig"></ckeditor> -->
<!--  -->


        </el-form-item>
        <!-- <el-form-item :label="'코드 템플릿'">
          <el-row>
            <el-col :span="24" v-for="(v, k) in template" :key="'template' + k">
              <el-form-item>
                <el-checkbox v-model="v.checked">{{ k }}</el-checkbox>
                <div v-if="v.checked">
                  <code-mirror v-model="v.code" :mode="v.mode"></code-mirror>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item> -->
      
        <el-row :gutter="20">
          <el-col :span="4">
            <!-- <el-form-item :label="'유형'">
              <el-radio-group
                v-model="problem.rule_type"
                :disabled="disableRuleType"
              > -->
            <!-- <el-radio label="ACM">ACM</el-radio> -->
            <!-- <el-radio label="OI">OI</el-radio>
              </el-radio-group>
            </el-form-item> -->
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <span ref="test-case"></span>
            <el-form-item
              id="test-case"
              label="테스트 케이스 업로드"
              :error="error.testcase"
            >
              <el-upload
                data-test-id ="upload-testcase"
                action="/api/admin/test_case"
                name="file"
                :data="{ spj: problem.spj }"
                :show-file-list="true"
                :on-success="uploadSucceeded"
                :on-error="uploadFailed"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >Choose File</el-button
                >
              </el-upload>
            </el-form-item>
          </el-col>
          <!--  -->
          <el-col v-if="this.mode === 'edit'" :span="6">
            <el-form-item
              label="테스트 케이스 다운로드"
              :error="error.testcase"
            >
              <el-button
                @click="downloadTestCase(problem.id)"
                size="small"
                type="primary"
                icon="el-icon-fa-download"
                >다운로드</el-button
              >
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <el-form-item label="테스트 케이스 다운로드 여부">
              <span class="visible-title"> 허용</span>

              <el-switch v-model="test_case_downloadable"></el-switch>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="6" :lg="6">
            <el-form-item label="채점 내역에서 테스트 케이스 일부 공개">
              <span class="visible-title"> 공개</span>

              <el-switch v-model="test_case_visible"></el-switch>
            </el-form-item>
          </el-col>
          <!--  -->
          <!-- <el-col :span="6">
            <el-form-item :label="$t('m.IOMode')">
              <el-radio-group v-model="problem.io_mode.io_mode">
                <el-radio label="Standard IO">Standard IO</el-radio>
                <el-radio label="File IO">File IO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->

          <el-col :span="4" v-if="problem.io_mode.io_mode == 'File IO'">
            <el-form-item :label="'Input File Name'" required>
              <el-input type="text" v-model="problem.io_mode.input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="problem.io_mode.io_mode == 'File IO'">
            <el-form-item :label="'Output File Name'" required>
              <el-input type="text" v-model="problem.io_mode.output"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-table :data="problem.test_case_score" style="width: 100%">
              <el-table-column prop="input_name" label="입력">
                <template slot-scope="scope">
                  {{ scope.row.input_name }}
                  <el-button
                    @click="getTestCase(problem.id, scope.row.input_name)"
                    style="margin-left: 10px"
                    size="mini"
                  >
                    수정</el-button
                  >
                  <!-- 버튼 추가하기  -->
                </template>
              </el-table-column>
              <el-table-column prop="output_name" label="출력">
                <template slot-scope="scope">
                  {{ scope.row.output_name }}

                  <el-button
                    @click="getTestCase(problem.id, scope.row.output_name)"
                    style="margin-left: 10px"
                    size="mini"
                  >
                    수정</el-button
                  >
                  <!-- 버튼 추가하기  -->
                </template>
              </el-table-column>
              <el-table-column prop="score" :label="'점수'">
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    :placeholder="'점수'"
                    v-model="scope.row.score"
                    :disabled="problem.rule_type !== 'OI'"
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column> </el-table-column>
            </el-table>
          </el-col>
        </el-row>

        <!-- <el-form-item label="강사님 코드 공개" :error="error.spj">
          <el-col :span="24">
            <el-switch v-model="teacher_code_visible"></el-switch>
            
          </el-col>
        </el-form-item> -->
        <span ref="tutor-code"></span>
        <el-form-item
          id="tutor-code"
          class="visible-container"
          label="강사님코드(C++)"
        >
          <div class="visible-wrapper">
            <span class="visible-title">정답시 공개</span>
            <hr />
            <el-switch v-model="teacher_code_visible"></el-switch>
          </div>
          <VueCkEditor
            :data="problem.source"
            @setContent="setSource"
          ></VueCkEditor>
        </el-form-item>

        <el-form-item class="visible-container" label="강사님코드(Java)">
          <div class="visible-wrapper">
            <span class="visible-title">정답시 공개</span>
            <hr />
            <el-switch v-model="teacher_code_visible"></el-switch>
          </div>
          <VueCkEditor
            :data="problem.source_java"
            @setContent="setJavaSource"
          ></VueCkEditor>
        </el-form-item>

        <el-form-item class="visible-container" label="강사님코드(Python)">
          <div class="visible-wrapper">
            <span class="visible-title">정답시 공개</span>
            <hr />
            <el-switch v-model="teacher_code_visible"></el-switch>
          </div>
          <VueCkEditor
            :data="problem.source_python"
            @setContent="setPythonSource"
          ></VueCkEditor>
        </el-form-item>

        <save data-test-id="save-button" @click.native="submit()">Save</save>
      </el-form>
      <el-dialog
        :class="$store.state.isMobile ? 'mobile-dialog' : ''"
        :width="$store.state.isMobile ? '100vw' : '55%'"
        top="1vh"
        :title="teseCaseFileName"
        :visible.sync="testCaseModal"
        :close-on-click-modal="false"
      >
        <CodeMirror
          class="code-mirror-problem-wrapper"
          v-model="testCaseText"
        ></CodeMirror>
        
        <div class="test-case-button-wrapper">
          <el-button @click="updateTestCase"  type="primary" size="small">{{ teseCaseFileName }} 수정하기 </el-button>
        </div>
        

      </el-dialog>
    </AdminPanel>
  </div>
</template>

<script>

import Accordion from "../../components/Accordion";
import CodeMirror from "../../components/CodeMirror";
import api from "../../api";
import utils from "@/utils/utils";

import AdminPanel from "@admin/components/AdminPanel.vue";
// import ckEditorMixin from "@/pages/admin/components/ckEditorMixin.js"
import VueCkEditor from "../../components/VueCkEditor.vue";
import AddTag from "./AddTag.vue";
import { types } from "@/store";

export default {
  mixins: [],
  name: "Problem",
  components: {

    Accordion,
    CodeMirror,
    AdminPanel,
    VueCkEditor,
    AddTag,
  },
  data() {
    return {
      // type
      pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
      rules: {
        _id: {
          required: true,
          message: "Display_Id는 필수입니다.",
          trigger: "blur",
          validator: this.validateId,
        },
        title: {
          required: true,
          message: "제목은 필수입니다.",
          trigger: "blur",
        },
        input_description: {
          // required: true,
          message: "입력설명은 필수입니다.",
          trigger: "blur",
        },
        output_description: {
          // required: true,
          message: "출력설명은 필수입니다.",
          trigger: "blur",
        },
      },
      language_time_limit: [
        { type: "C++", time_limit: 1000 },
        { type: "Java", time_limit: 1000 },
        { type: "Python3", time_limit: 5000 },
      ],
      loadingCompile: false,
      mode: "",
      contest: {},
      teacher_code_visible: true,
      main_code_visible: false,
      test_case_visible: true,
      test_case_downloadable: false,
      main_code: "",
      user_code: "",
      java_main_code: "",
      java_user_code: "",
      java_main_code_description: "",
      python_main_code: "",
      python_user_code: "",
      python_main_code_description: "",
      main_code_description: "",
      problem: {
        test_case_visible: true,
        // 1이 공개 2가 비공개
        spj_language: "C++",
        teacher_code_visible: false,
        test_case_downloadable: false,
        languages: [],
        io_mode: {
          io_mode: "Standard IO",
          input: "input.txt",
          output: "output.txt",
        },
        main_code_description: "",
      },
      reProblem: {
        languages: [],
        io_mode: {
          io_mode: "Standard IO",
          input: "input.txt",
          output: "output.txt",
        },
      },
      testCaseUploaded: false,
      allLanguage: {},
      inputVisible: false,
      tagInput: "",
      template: {},
      title: "",
      spjMode: "",
      disableRuleType: false,

      routeName: "",
      error: {
        tags: "",
        spj: "",
        languages: "",
        testCase: "",
      },
      language: "C++",
      theme: "solarized",
      tagModal: false,
      testCaseModal: false,
      testCaseText: "",
      teseCaseFileName: ""
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    if (
      this.routeName === "edit-problem" ||
      this.routeName === "edit-contest-problem"
    ) {
      this.mode = "edit";
    } else {
      this.mode = "add";
    }

    api.getLanguages().then((res) => {
      this.problem = this.reProblem = {
        _id: "",
        title: "",
        description: "",
        input_description: "",
        output_description: "",
        time_limit: 1000,
        memory_limit: 256,
        difficulty: "-",
        visible: true,
        share_submission: false,
        tags: [],
        languages: [],
        template: {},
        samples: [{ input: "", output: "" }],
        spj: false,
        spj_language: "C++",
        spj_code: "",
        spj_compile_ok: false,
        test_case_id: "",
        test_case_score: [],
        rule_type: "OI",
        main_code_visible: false,
        main_code: "",
        test_case_visible: true,
        test_case_downloadable: false,
        user_code: "",
        java_main_code: "",
        java_user_code: "",
        python_main_code: "",
        python_user_code: "",
        hint: "",
        source: "",
        source_java: "",
        source_python: "",
        io_mode: {
          io_mode: "Standard IO",
          input: "input.txt",
          output: "output.txt",
        },
      };
      let contestID = this.$route.params.contestId;
      if (contestID) {
        this.problem.contest_id = this.reProblem.contest_id = contestID;
        this.disableRuleType = true;
        api.getContest(contestID).then((res) => {
          this.problem.rule_type = this.reProblem.rule_type =
            res.data.data.rule_type;
          this.contest = res.data.data;
        });
      }

      this.problem.spj_language = "C++";

      let allLanguage = res.data.data;
      this.allLanguage = allLanguage;

      // get problem after getting languages list to avoid find undefined value in `watch problem.languages`
      if (this.mode === "edit") {
        this.title = "문제 수정";
        let funcName = {
          "edit-problem": "getProblem",
          "edit-contest-problem": "getContestProblem",
        }[this.routeName];
        api[funcName](this.$route.params.problemId).then((problemRes) => {
          let data = problemRes.data.data;
          console.log(data);

          if (data.tags.length) {
            this.$store.commit(types.SET_TAG, data.tags);
          }

          if (!data.spj_code) {
            data.spj_code = "";
          }
          data.spj_language = data.spj_language || "C";

          // edit 문제시 버그 수정
          if (!data.main_code) data.main_code = "";
          if (!data.user_code) data.user_code = "";
          if (!data.java_main_code) data.java_main_code = "";
          if (!data.java_user_code) data.java_user_code = "";
          if (!data.python_main_code) data.python_main_code = "";
          if (!data.python_user_code) data.python_user_code = "";
          this.problem = data;

          if (this.problem["language_time_limit"]) {
            this.language_time_limit = this.problem["language_time_limit"];
          }

          this.teacher_code_visible = this.problem["teacher_code_visible"];
          this.test_case_visible = this.problem["test_case_visible"];
          this.test_case_downloadable = this.problem["test_case_downloadable"];
          this.testCaseUploaded = true;
        });
      } else {
        this.title = "문제 만들기";
        this.$store.commit(types.CLEAR_TAG);
        for (let item of allLanguage.languages) {
          this.problem.languages.push(item.name);
        }
      }
    });
  },
  watch: {
    $route() {
      this.$refs.form.resetFields();
      this.problem = this.reProblem;
    },
    "problem.languages"(newVal) {
      let data = {};
      // use deep copy to avoid infinite loop
      let languages = JSON.parse(JSON.stringify(newVal)).sort();
      for (let item of languages) {
        if (this.template[item] === undefined) {
          let langConfig = this.allLanguage.languages.find((lang) => {
            return lang.name === item;
          });
          if (this.problem.template[item] === undefined) {
            data[item] = {
              checked: false,
              code: langConfig.config.template,
              mode: langConfig.content_type,
            };
          } else {
            data[item] = {
              checked: true,
              code: this.problem.template[item],
              mode: langConfig.content_type,
            };
          }
        } else {
          data[item] = this.template[item];
        }
      }
      this.template = data;
    },
    "problem.spj_language"(newVal) {
      this.spjMode = this.allLanguage.spj_languages.find((item) => {
        return item.name === this.problem.spj_language;
      }).content_type;
    },
  },
  methods: {
    switchSpj() {
      if (this.testCaseUploaded) {
        this.$confirm(
          "문제 판단 방식을 변경 한 경우 테스트 케이스를 다시 업로드해야합니다.",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.problem.spj = !this.problem.spj;
            this.resetTestCase();
          })
          .catch(() => {});
      } else {
        this.problem.spj = !this.problem.spj;
      }
    },
    querySearch(queryString, cb) {
      api
        .getProblemTagList()
        .then((res) => {
          let tagList = [];
          for (let tag of res.data.data) {
            tagList.push({ value: tag.name });
          }
          cb(tagList);
        })
        .catch(() => {});
    },
    resetTestCase() {
      this.testCaseUploaded = false;
      this.problem.test_case_score = [];
      this.problem.test_case_id = "";
    },
    // addTag() {
    //   let inputValue = this.tagInput;
    //   if (inputValue) {
    //     this.problem.tags.push(inputValue);
    //   }
    //   this.inputVisible = false;
    //   this.tagInput = "";
    // },
    // closeTag(tag) {
    //   this.problem.tags.splice(this.problem.tags.indexOf(tag), 1);
    // },
    addSample() {
      this.problem.samples.push({ input: "", output: "" });
    },
    deleteSample(index) {
      this.problem.samples.splice(index, 1);
    },
    uploadSucceeded(response) {
      if (response.error) {
        this.$error(response.data);
        return;
      }
      let fileList = response.data.info;
      let oneScore = (100 / fileList.length).toFixed(0);
      for (let file of fileList) {
        file.score = oneScore;
        if (!file.output_name && this.problem.spj) {
          file.output_name = "-";
        }
      }
      fileList[0].score = 100 - (fileList.length - 1) * oneScore;

      this.problem.test_case_score = fileList;
      this.testCaseUploaded = true;
      this.problem.test_case_id = response.data.id;
    },
    uploadFailed(src, fail) {
      const memoryByteLimit = 104857600;
      // 100M를 byte로 변화시켰을때
      let size = fail.size;
      // console.log(size)
      // console.log(size > memoryByteLimit)
      if (size > memoryByteLimit) {
        const CUTOFF = 1024;
        const byteArr = ["BYTE", "KB", "MB", "GB", "TB"];
        let cnt = 0;
        while (size > CUTOFF) {
          size = size / CUTOFF;
          cnt = cnt + 1;
        }
        size = size.toFixed(2);
        alert(
          `최대 업로드 사이즈 (100 MB)를 초과 하였습니다.\n현재 사이즈 : ${size} ${byteArr[cnt]}`
        );
      }
      this.$error("업로드에 실패하였습니다.");
    },
    compileSPJ() {
      let data = {
        id: this.problem.id,
        spj_code: this.problem.spj_code,
        spj_language: this.problem.spj_language,
      };
      this.loadingCompile = true;
      api.compileSPJ(data).then(
        (res) => {
          this.loadingCompile = false;
          this.problem.spj_compile_ok = true;
          this.error.spj = "";
        },
        (err) => {
          this.loadingCompile = false;
          this.problem.spj_compile_ok = false;
          const h = this.$createElement;
          this.$msgbox({
            title: "Compile Error",
            type: "error",
            message: h("pre", err.data.data),
            showCancelButton: false,
            closeOnClickModal: false,
            customClass: "dialog-compile-error",
          });
        }
      );
    },
    submit() {
      const pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;

      if (pattern.test(this.problem._id) && this.mode !== "edit") {
        this.$error("Display ID는 영문또는 숫자만 입력이 가능합니다.");

        return;
      }

      // if (!this.problem.samples.length) {
      //   this.$error("샘플은 필수입니다.");
      //   return;
      // }
      // for (let sample of this.problem.samples) {
      //   if (!sample.input || !sample.output) {
      //     this.$error("샘플입력과 출력은 필수입니다.");
      //     return;
      //   }
      // }

      this.problem.tags = this.$store.state.problem.tagList;

      // if (!this.problem.tags.length) {
      //   this.error.tags = "Please add at least one tag";
      //   this.$error(this.error.tags);
      //   return;
      // }

      if (this.problem.spj) {
        if (!this.problem.spj_code) {
          this.error.spj = "Spj code is required";
          this.$error(this.error.spj);
        } else if (!this.problem.spj_compile_ok) {
          this.error.spj = "SPJ code has not been successfully compiled";
        }
        if (this.error.spj) {
          this.$error(this.error.spj);
          return;
        }
      }
      if (!this.problem.languages.length) {
        this.error.languages =
          "Please choose at least one language for problem";
        this.$error(this.error.languages);
        return;
      }
      // if (!this.testCaseUploaded) {
      //   this.error.testCase = "테스트 케이스가 아직 업로드되지 않았습니다.";
      //   this.$error(this.error.testCase);
      //   return;
      // }
      if (this.problem.rule_type === "OI") {
        for (let item of this.problem.test_case_score) {
          try {
            if (parseInt(item.score) <= 0) {
              this.$error("잘못된 테스트 케이스 점수");
              return;
            }
          } catch (e) {
            this.$error("테스트 케이스 점수는 정수 여야합니다.");
            return;
          }
        }
      }

      if (!this.problem.main_code_description) {
        this.problem.main_code_description = "";
      }

      if (!this.problem.java_main_code_description) {
        this.problem.java_main_code_description = "";
      }

      if (!this.problem.python_main_code_description) {
        this.problem.python_main_code_description = "";
      }

      this.problem.language_time_limit = this.language_time_limit;
      this.problem.languages = this.problem.languages.sort();
      this.problem.template = {};
      for (let k in this.template) {
        if (this.template[k].checked) {
          this.problem.template[k] = this.template[k].code;
        }
      }
      this.problem["teacher_code_visible"] = this["teacher_code_visible"];
      this.problem["test_case_visible"] = this["test_case_visible"];
      this.problem["test_case_downloadable"] = this["test_case_downloadable"];
      let funcName = {
        "create-problem": "createProblem",
        "edit-problem": "editProblem",
        "create-contest-problem": "createContestProblem",
        "edit-contest-problem": "editContestProblem",
      }[this.routeName];
      // edit contest problem 시각, contest_id후속 요청으로 덮어 쓰기됩니다.
      if (funcName === "editContestProblem") {
        this.problem.contest_id = this.contest.id;
      }

      api[funcName](this.problem)
        .then((res) => {
          this.$store.commit(types.CLEAR_TAG);
          if (
            this.routeName === "create-contest-problem" ||
            this.routeName === "edit-contest-problem"
          ) {
            this.$router.push({
              name: "admin-contest-problem-list",
              params: { contestId: this.$route.params.contestId },
            });
          } else {
            this.$router.push("/admin/problems");
          }
        })
        .catch(() => {});
    },
    downloadTestCase(problemID) {
      let url = "/admin/test_case?problem_id=" + problemID;
      utils.downloadFile(url);
    },
    onChangeLang(newLang) {
      if (this.problem.template[newLang]) {
        if (this.code.trim() === "") {
          this.code = this.problem.template[newLang];
        }
      }
      this.language = newLang;
    },
    onChangeTheme(newTheme) {
      this.theme = newTheme;
    },
    onResetToTemplate() {
      this.$Modal.confirm({
        content: this.$i18n.t("m.Are_you_sure_you_want_to_reset_your_code"),
        onOk: () => {
          let template = this.problem.template;
          if (template && template[this.language]) {
            this.code = template[this.language];
          } else {
            this.code = "";
          }
        },
      });
    },
    setDescription(e) {
      this.problem.description = e;
    },
    setInputDescription(e) {
      this.problem.input_description = e;
    },
    setOutputDescription(e) {
      this.problem.output_description = e;
    },
    setHint(e) {
      this.problem.hint = e;
    },
    setSource(e) {
      this.problem.source = e;
    },
    setJavaSource(e) {
      this.problem.source_java = e;
    },
    setPythonSource(e) {
      this.problem.source_python = e;
    },
    setMainCodeDescription(e) {
      this.problem.main_code_description = e;
    },

    setJavaMainCodeDescription(e) {
      this.problem.java_main_code_description = e;
    },
    setPythonMainCodeDescription(e) {
      this.problem.python_main_code_description = e;
    },
    handleTag() {
      this.tagModal = true;
    },
    handleTestCaseModal() {
      this.testCaseModal = true;
    },
    handleClose(tag) {
      this.$store.commit(types.REMOVE_TAG, tag);
    },

    validateId(rule, value, callback) {
      if (!value) {
        rule.message = "Display_ID는 필수입니다. ";
        callback(new Error(""));
      } else if (this.pattern.test(value)) {
        rule.message = "영문 또는 숫자만 입력이 가능합니다.";

        callback(new Error(""));
      } else {
        this.rules._id.message = "";
        callback();
      }
    },
    move(target) {
      this.$refs[target].scrollIntoView({ behavior: "smooth" });
    },
    async getTestCase(problem_id, file_name) {
      const result = await api.getTestCase({ problem_id, file_name });
      this.testCaseModal = true;
      this.teseCaseFileName = file_name;
      this.testCaseText = result.data.data;
      

    },
    updateTestCase(){
      const confirmResult = confirm("해당 파일을 업데이트 하시겠습니까?")
      if(confirmResult){
        //테스트 케이스 업데이트 로직 추가하기
        const data = {
          problem_id: this.problem.id,
          file_name: this.teseCaseFileName,
          test_case_text: this.testCaseText
        }
        api.updateTestCase(data)
        

        this.testCaseModal = false;
        
      }
    }
  },
};
</script>

<style lang="less" scoped>
.problem {
  .float-wrapper {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }
  .float-button {
    margin-top: 8px;
    margin-left: 0;
    width: 140px;
    font-size: 11px;
  }

  .spj-radio {
    margin-left: 10px;
    &:last-child {
      margin-right: 20px;
    }
  }
  .input-new-tag {
    width: 78px;
  }
  .button-new-tag {
    height: 24px;
    line-height: 22px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tags {
    .el-tag {
      margin-right: 10px;
    }
  }
  .accordion {
    margin-bottom: 10px;
  }
  .add-samples {
    width: 100%;
    background-color: #fff;
    border: 1px dashed #aaa;
    outline: none;
    cursor: pointer;
    color: #666;
    height: 35px;
    font-size: 14px;
    &:hover {
      background-color: #f9fafc;
    }
    i {
      margin-right: 10px;
    }
  }
  .add-sample-btn {
    margin-bottom: 10px;
  }
}
</style>

<style>
.dialog-compile-error {
  width: auto;
  max-width: 80%;
  overflow-x: scroll;
}
.visible-container {
  margin-top: 50px;
  position: relative;
}
.visible-title {
  margin-right: 10px;
}
.visible-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: -49px;
  right: 20px;
}

.code-mirror-problem-wrapper .CodeMirror-scroll {
  max-height: 600px !important;
}
.el-form-item__content .code-mirror-problem-wrapper {
  max-width: 80%;
}
.test-case-button-wrapper{
  text-align: right;
  padding-top: 16px;
}

/* .code-mirror-problem-wrapper .vue-codemirror-wrap{
  
} */
</style>
