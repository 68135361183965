<template>
  <el-menu
    class="vertical_menu"
    :router="true"
    :default-active="currentPath"
    @select="handleSelect"
  >
    <i
      class="el-icon-close"
      @click="closeMenu"
      v-if="$store.state.isMobile"
    ></i>
    <div class="logo" @click="goHome">
      <img src="../../../assets/logo_01.svg" alt="oj admin" />
    </div>
    <el-menu-item @click="goHome" index=""
      ><i class="el-icon-fa-home"></i>Home</el-menu-item
    >

    <el-menu-item index="/admin"
      ><i class="el-icon-fa-dashboard"></i>대시보드</el-menu-item
    >
    <el-submenu v-if="isAdminRole || isManager" index="general">
      <template slot="title"><i class="el-icon-menu"></i>기본설정</template>
      <el-menu-item index="/admin/user">회원정보</el-menu-item>
      <el-menu-item v-if="isAdminRole" index="/admin/announcement">NEWS</el-menu-item>
      <el-menu-item v-if="isSuperAdmin" index="/admin/conf">시스템 설정</el-menu-item>
      <el-menu-item v-if="isAdminRole" index="/admin/judge-server">채점 서버</el-menu-item>
      <el-menu-item  v-if="isSuperAdmin" index="/admin/judge-server-last">채점 서버 (정보)</el-menu-item>
      <el-menu-item v-if="isSuperAdmin" index="/admin/recovery">시스템 복구</el-menu-item>
      <el-menu-item v-if="!$store.state.isMobile && isSuperAdmin" index="/admin/prune-test-case"
        >테스트 케이스 가지치기</el-menu-item
      >
    </el-submenu>
    <el-submenu v-if="isAdminRole" index="problem" >
      <template  slot="title"><i  data-test-id="problem-submenu"  class="el-icon-fa-bars"></i>문제</template>
      <el-menu-item data-test-id="problem-submenu-list" index="/admin/problems">문제 목록</el-menu-item>
      <el-menu-item data-test-id="problem-submenu-create" index="/admin/problem/create">문제 만들기</el-menu-item>
      <el-menu-item data-test-id="problem-submenu-tag-list" index="/admin/problem/tag">태그 목록</el-menu-item>
      <el-menu-item data-test-id="problem-submenu-step" index="/admin/problem-steps">
        Step 별 문제 목록
      </el-menu-item>
      <el-menu-item data-test-id="problem-submenu-step-create" index="/admin/problem-steps/create">
        Step 만들기
      </el-menu-item>
      <el-menu-item
        v-if="!$store.state.isMobile && isSuperAdmin"
        index="/admin/problem/batch_ops"
        >Export/Import</el-menu-item
      >
    </el-submenu>
    <el-submenu  v-if="isAdminRole"  index="secret_code_auth">
      <template slot="title"><i class="el-icon-setting"></i>그룹관리</template>
      <!-- <el-menu-item index="/mincoding_auth/create">
        권한 생성하기
      </el-menu-item> -->
      <!-- <el-menu-item index="/mincoding_auth">
        권한 목록
      </el-menu-item> -->
      <el-menu-item index="/admin/secret_code_auth"> 그룹 목록 </el-menu-item>
      <el-menu-item index="/admin/secret_code_auth/create"
        >그룹 생성하기</el-menu-item
      >
    </el-submenu>

    <el-submenu index="contest">
      <template slot="title"
        ><i class="el-icon-fa-id-badge"></i>집합교육</template
      >
      <el-menu-item index="/admin/contest">집합교육 목록</el-menu-item>
      <el-menu-item v-if="isAdminRole"   index="/admin/contest/create"> 강의생성하기 </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["title"],
  name: "SideMenu",
  data() {
    return {
      currentPath: "",
      mainPath: "",
    };
  },
  mounted() {
    this.mainPath = window.location.origin;
    this.currentPath = this.$route.fullPath;
  },
  methods: {
    goHome() {
      window.open(this.mainPath);
    },
    closeMenu() {
      this.$emit("closeMenu");
    },
    handleSelect() {
      this.$emit("closeMenu");
    },
  },
  computed: {
    ...mapGetters(["user", "isSuperAdmin", "hasProblemPermission", "isManager", "isAdminRole"]),
  },
};
</script>

<style scoped lang="less">
.el-icon-close {
  font-size: 30px;
  cursor: pointer;
  left: 0%;
  padding-left: 80%;
  padding-top: 1%;
}

.vertical_menu {
  overflow: auto;
  min-width: 195px;
  height: 100%;
  // position: fixed !important;
  z-index: 100;
  // top: 0;
  // bottom: 0;
  // left: 0;
  .logo {
    margin: 20px 0;
    text-align: center;
    cursor: pointer;
    img {
      background-color: #fff;
      border-radius: 50%;
      border: 3px solid #fff;
      width: 75px;
      height: 75px;
    }
  }
}
</style>
